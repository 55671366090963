import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../../components/DisplayDateFlu/DisplayDateFluNew'
import DisplayDateProvider from '../../../components/DisplayDateProvider/DisplayDateProvider'
import Spinner from '../../../components/Loader/Spinner'
import EmptyMap from '../../../components/Map/emptyMap'
import Timeline from '../../../components/Timeline'
import Map from '../../../components/Map'
import toolTipFormatter from '../toolTipFormatter'
import MapLegend from '../cdcMapLegendVertical'
import SideTableProvider from './SideTableProvider'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ProviderDetails from './ProviderDetails'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../../components/AuthProvider'
import ProviderMapMarker from '../ProviderMapMarker'
// import ProviderMapMarkerLatest from '../../FluDashboard/ProviderMapMarkerLatest'
// import StateWithProviderMarker from '../../FluDashboard/StateWithProviderMarker'
const DashboardMap = ({ geo_type, geo_parent, zoomLocation }) => {
  const [getforecast_level, setforecast_level] = useState()
  const { user } = useAuthState()
  const mapRef = useRef();
  const geojson = useRef()
  // const entity = 'flu'
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const { loc, area } = useParams()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [metros, setMetros] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [getStats, setStats] = useState()
  const [getStatsAll, setStatsAll] = useState()
  const [getAllProvider, setAllProvider] = useState()
  // const [showProvidersMap, setshowProvidersMap] = useState(false)
  const [getProviderDropDown, setProviderDropDown] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [getStateJson, setStateJson] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
    showProvidersMap,
  } = useGeoState()
  const [getProvider, setProvider] = useState()
  const [getsideTable, setsideTable] = useState()

  if (area === 'MSA') {
    setRegionSelected(area)
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'MSA/?geo_parent=' + geo_parent
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Msa Dashboard success',
          user: user,
        })
        setMetros(msaData.response_data)
        var data = Object(msaData.response_data)[lastweekEndDate][parseInt(loc)].forecast_level
        setforecast_level(data)
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=MSA&geo_parent_code=' + geo_parent
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  let type = hash.length > 0 ? hash.replace('#', '') : ''
  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProviders',
    geo_parent + `/${loc}?disease_type=FLU&filter=${type}`
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {        
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  const { data: statsall, error: stateallDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProviders',
    `US/${geo_parent}?disease_type=FLU&filter=${type}`
  )

  useEffect(() => {
    if (statsall !== null && statsall !== undefined) {
      if (statsall.response_code === 200) {
        setStatsAll(statsall.response_data)
      } else {
        setErrorCode(statsall.response_code)
        setErrorMessage(statsall.response_message)
        setErrorSubject(statsall.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statsall])


  const { data: allProvider, error: allProviderDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProviders',
    `US/${'0'}?disease_type=FLU&filter=${type}`
  )

  useEffect(() => {
    if (allProvider !== null && allProvider !== undefined) {
      if (allProvider.response_code === 200) {
        setAllProvider(allProvider.response_data)
      } else {
        setErrorCode(allProvider.response_code)
        setErrorMessage(allProvider.response_message)
        setErrorSubject(allProvider.response_subject)
        seterrorOccured(true)
      }
    }
  }, [allProvider])

  const { data: providerDropDown, error: dropdownError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProvidersFilter',
    geo_parent + `/${loc}`
  )
  useEffect(() => {
    if (providerDropDown !== null && providerDropDown !== undefined) {
      if (providerDropDown.response_code === 200) {
        setProviderDropDown(providerDropDown.response_data)
      } else {
        setErrorCode(providerDropDown.response_code)
        setErrorMessage(providerDropDown.response_message)
        setErrorSubject(providerDropDown.response_subject)
        seterrorOccured(true)
      }
    }
  }, [providerDropDown])

  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])

  if (
    stateDataError ||
    MapError ||
    errorOccured ||
    MapmsaError ||
    activeCbsasDataError ||
    dropdownError ||
    stateallDataError
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  const chooseMessage = (data) => {
    setProvider(data)
  }
  const callsideTable = (data) => {
    setsideTable(data)
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'MSA' && (
            <>
              {getStats !== undefined && getProviderDropDown !== undefined ? (
                <SideTableProvider
                  dropdownData={getProviderDropDown}
                  stateData={getStats}
                  chooseMessage={chooseMessage}                 
                  callsideTable={callsideTable}
                  mapRef={mapRef}
                  
                />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'MSA' && (
          <>
            {metros !== undefined &&
            getMapStates !== undefined &&
            activeCbsa !== undefined ? (
              <>
                {getProvider && (
                  <ProviderDetails
                    providerData={getProvider}
                    chooseMessage={chooseMessage}
                  />
                )}               
                {!showProvidersMap && getStatsAll && getStats && (
                  <>
                    <div className={`${styles.providerHeaderCollection}`}>
                      <DisplayDateProvider
                       textShow={'CDO Locations and Forecast for Epiweek '}
                        showWeek={true}
                        lastweekEndDate={lastweekEndDate}
                      />
                    </div>
                    {getStats && metros &&  <MapLegend geojson={geojson} provider={true} thresholdVal={getforecast_level} />}
                   {metros &&  <ProviderMapMarker geojson={geojson} thresholdVal={getforecast_level} cbsaData={metros} providers={getStats} msaid={loc}  />}                   
                    

                  
                  </>
                )}
              </>
            ) : (
              <EmptyMap />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap