import React, { useState, useEffect } from 'react'
import styles from './FluIndices.module.scss'
import getVectorImage from '../../utils/getVectorImage'
import { useGeoFetch } from '../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from '../Dashboard/typeDisplayOptions'
import SelectList from '../../components/SelectMenuList'
import DefaultBreadcrumbs from '../../components/Breadcrumbs/DefaultBreadcrumbs'
import regionDisplayOptions from './regionDisplayOptions'
import MapOptions from '../../components/MapOptions'
const HeaderDisplay = ({ headerType }) => {
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const [getgeoid, setgeoid] = useState('')
  const [getistrue, setistrue] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [getPercentage, setPercentage] = useState('')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvHeader',
    headerType
  )
  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {
        if (
          headerdata.response_data !== null &&
          headerdata.response_data !== undefined
        ) {
          // setHeaderData(headerdata.response_data)
          setgeoid(headerdata.response_data.geo_id)
          setistrue(headerdata.response_data.istrue)
          setPercentage(headerdata.response_data.percentage)
          setDatavalue(headerdata.response_data.datavalue)
        }
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])

  if (stateDataError || errorOccured) {
    return (
      <div>
        {errorSubject !== undefined
          ? errorSubject
          : 'Unable to get the data for header'}{' '}
        : {errorSubject}
      </div>
    )
  }
  return (
    <>
      <span className={styles.firstSpan}> {getgeoid}</span>
      <span className={styles.secondSpan}>{getVectorImage(getistrue)}</span>
      <span className={getistrue ? styles.stdatavalueup : styles.datavaluedown}>
        {' '}
        {getPercentage}%{' '}
      </span>
      <span className={styles.forthSpan}> ({getDatavalue})</span>
    </>
  )
}

const Header = ({ headerType, headerNumber }) => {
  const [searchCriteria, setSearchCriteria] = React.useState([])
  const [activeCbsa, setActiveCbsa] = useState()
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/RSV?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  return (
    <>
      {/* <div style={{ display: 'flex' }}>
        <div style={{ width: '80%' }}>
          <DefaultBreadcrumbs
            geo_id={'NAT'}
            geo_type={'NAT'}
            dashboardType={'RSV'}
          />
        </div>
        <div style={{ width: '20%' }}>
          <Search
            criteria={searchCriteria}
            onChange={setSearchCriteria}
            entity={'rsv'}
            activeMetros={null}
          />
        </div>
      </div> */}

      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
          <DefaultBreadcrumbs
            geo_id={'NAT'}
            geo_type={'NAT'}
            dashboardType={'RSV'}
          />
          <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'rsv'}
            defaultLabel={'RSV Incidence'}
          />
        </div>
        <div className={styles.pageHeaderTextValue}>
          <HeaderDisplay
            headerType={headerType}
            dashNo={headerNumber}
          ></HeaderDisplay>
            <MapOptions regionDisplayOptions={regionDisplayOptions} activeCbsa={activeCbsa} entity={'RSV'}/>
          {/* <div className={styles.pageHeaderSearch}>
            {activeCbsa && (
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                entity={'RSV'}
                activeMetros={activeCbsa}
              />
            )}
          </div> */}
        </div>
      </div>
    </>
  )
}
export default Header
