import React, { useRef, useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { capitalize } from '../../utils/capitise'
import GeoProvider, { useGeoFetch } from '../../components/GeoProvider'
import Map from '../../components/Map'
import toolTipFormatter from '../FluDashboard/toolTipFormatter'
import dataDisplayOptions from '../FluDashboard/dataDisplayOptions'
import { endDate, startDate } from '../Dashboard/dates'
import MapLegend from '../FluDashboard/cdcMapLegendHorizontal'
import styles from './MSARiskMap.module.scss'
import { displayDate } from '../FluDashboard/dates'
import { useStateDataFetch } from '../../hooks/useStateDataFetch'
import { useCBSADataFetch } from '../../hooks/useCBSADataFetch'
import { useMSADataFetch } from '../../hooks/useMSADataFetch'
import { useStatesJson } from '../../hooks/useStatesJson'
import EmptyMap from '../../components/Map/emptyMap'
import ErrorMessage from '../../components/Layout/ErrorMessage'

const MSARiskMap = () => {
  //path ---> /RiskMap/disease/flu/STATE/TX
  const location = useLocation()
  const locationValues = location?.pathname?.split('/')
  const geoCode = locationValues.at(-1).toUpperCase()
  const diseaseType = locationValues.at(-3)
  const diseaseLabel = capitalize(diseaseType)
  const DEFAULT_MAP_EXPORT = false

  const queryParams = new URLSearchParams(location.search)
  const exportMap = queryParams.get('export')

  let enableMapExport = exportMap === 'true' ? true : DEFAULT_MAP_EXPORT

  const chartRef = useRef(null)
  const [cbsaJSONData, setCbsaJSONData] = useState()
  const [countyJSONData, setCountyJSONData] = useState()

  const {
    data: statesMapData,
    loading: statesLoading,
    error: statesMapDataError,
  } = useStateDataFetch(startDate, endDate, diseaseType)

  const {
    data: cbsaData,
    loading: cbsaLoading,
    error: cbsaError,
  } = useCBSADataFetch(startDate, endDate, diseaseType)

  const {
    data: msaMapData,
    loading: msaLoading,
    error: msaMapError,
  } = useMSADataFetch(startDate, endDate, diseaseType)

  const { data: stateJson } = useStatesJson()

  const filteredStateJSON = useMemo(() => {
    const filteredState = stateJson?.features?.find(
      (state) => state.properties['hc-a2']?.toUpperCase() === geoCode
    )
    if (filteredState) {
      return { ...stateJson, features: [filteredState] }
    } else {
      return stateJson
    }
  }, [geoCode, stateJson])

  useEffect(() => {
    async function fetchCountyMapData() {
      try {
        let url = `${window.location.origin}/county.json`
        const response = await fetch(url)
        const data = await response.json()
        const filteredCounties = data?.features?.filter((feature) => {
          return feature.properties.STUSPS?.toUpperCase() == geoCode
        })
        const updatedCountyObj = {
          ...data,
          features: [...filteredCounties],
        }
        setCountyJSONData(updatedCountyObj)
      } catch (error) {
        console.log(error, 'Failed to fetch County JSON data ')
      }
    }

    fetchCountyMapData()
  }, [])

  useEffect(() => {
    async function fetchCBSAJsonData() {
      try {
        let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
        const response = await fetch(url)
        const data = await response.json()
        const filteredCbsaStateWise = data?.features?.filter((feature) =>
          feature.properties.NAME.split(',')[1].includes(geoCode)
        )
        const updatedCBSAObj = {
          ...data,
          features: [...filteredCbsaStateWise],
        }

        setCbsaJSONData(updatedCBSAObj)
      } catch (error) {
        console.log(error, 'Failed to fetch CBSA JSON data ')
      }
    }
    fetchCBSAJsonData()
  }, [])

  if (statesMapDataError || cbsaError || msaMapError) {
    return (
      <ErrorMessage
        errorCode={'Error'}
        errorMessage={'Failed to load the map'}
        errorSubject={'Map load failed'}
        errorDetails={
          'Some error occurred. Please refresh your screen and try again.'
        }
      />
    )
  }

  return (
    <div className={styles.base}>
      {(statesLoading || msaLoading || cbsaLoading) && (
        <EmptyMap stateJson={stateJson} />
      )}
      {statesMapData &&
        cbsaData &&
        msaMapData &&
        stateJson &&
        cbsaJSONData &&
        countyJSONData && (
          <div className={styles.map_container}>
            <MapLegend />
            <GeoProvider displayDate={displayDate}>
              <Map
                toolTipFormatter={toolTipFormatter}
                activeCbsasData={cbsaData}
                statesData={statesMapData}
                cbsaData={msaMapData}
                mapOptions={dataDisplayOptions[0]['options'][0]}
                dynamicMapTitle={true}
                mapType="RSVMAPMSA"
                chartRef={chartRef}
                mapDataType={1}
                JsonData={cbsaJSONData}
                stateJson={filteredStateJSON}
                dashboardType="FLU"
                zip3Data={null}
                countyData={null}
                countyGeoJson={countyJSONData}
                mapHeight={600}
                mapWidth={1200}
                highlightCBSA={true}
                exportMap={enableMapExport}
              />
            </GeoProvider>
          </div>
        )}
    </div>
  )
}

export default MSARiskMap
