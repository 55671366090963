import getVectorImage from '../../utils/getVectorImage'
import getThresholdValue from '../../utils/getThresholdValue'
import toTitleCase from './../../utils/toTitleCase'
import moment from 'moment'
function toolTipFormatter() {
  let imageName = (this.point.istrue)? "Vector-up-red.png":"Vector-down-green.png"
  var url =  `${window.location.origin}/${imageName}`; 
  let threshold = getThresholdValue(this.point.forecast_level).threshold  
  let stokeColor = getThresholdValue(this.point.forecast_level).OutlineColor
  let fillColor = getThresholdValue(this.point.forecast_level).color
  let color = '#007000'
  if (this.point.istrue) color = '#C40000'

  const divData =
    //return
    `<div style="padding-top:16px;padding-left: 16px;padding-right: 16px;height:160px;background-color:white;font-family:Optum Sans;border: 1px solid #CBCCCD;
    border-radius: 4px;">
    <div style="width:90%; padding-top:16px;padding-right:5px;display:inline;
    color: #002677;font-family:'Optum Sans';font-style: normal;font-weight: 700;
    font-size: 14px;line-height: 18px;
    ">${this.point.geo_name} </div>
    <br/>
    <span style="font-style: normal; padding-top:8px;font-weight: 700;
    font-size:14px; line-height: 18px; display: flex; align-items: center;
    color: #4B4D4F;">${toTitleCase(this.point.data_type)}s for Epiweek ${
      this.point.epiweek
    }: ${moment(this.point.week_ending).format('MMM DD, YYYY')}</span>
    <div style="padding-top:8px;">
    <div style="border-radius:0%;background-color:${fillColor}; 
    display: inline-block; width:16px; height:16px;border:1px solid ${stokeColor}"></div>&nbsp;
    <div style='display: inline-block; font-size:14px;font-weight:400;vertical-align:super;'>${threshold}</div>
    </div>
    <div>
    <span style="font-style: normal; padding-top:8px;font-weight: 400;
    font-size:14px; line-height: 18px; display: flex; align-items: center;
    color: #4B4D4F;">Incidence Rate :<span style="font-family: 'Optum Sans', sans-serif;
    font-style:normal;font-weight: 700;font-size: 14px;
    line-height:18px;display: flex;align-items:center;
    color: #4B4D4F;padding:2px;
    ">${this.point.data_value}</span></span>                
     </div>    
     <div style="
     width: 157px;
     padding-top: 8px;
     padding-bottom: 16px;
     font-family: 'Optum Sans', sans-serif;
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 18px;
     display: flex;
     align-items: center;
     color: #4B4D4F;
     ">
 
    
    <div  style="font-weight:bold; color:${color};padding:2px; display:flex;">    
    
    <div style="height:18px; width:16px; margin-top:0px">
    <img src="${url}"/>
    </div>
    <div style="margin-left:4px;margin-right:4px"> ${
      this.point.change_actual_percentage
    }% 
    </div>
    </div>     from previous week
    </div>
    </div>`
  return divData
}

export default toolTipFormatter
