import React, { useState, useEffect } from 'react'
import styles from './FluIndices.module.scss'
import getVectorImage from '../../utils/getVectorImage'
import { useGeoFetch, useGeoState } from '../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from '../Dashboard/typeDisplayOptions'
import SelectList from '../../components/SelectMenuList'
import DefaultBreadcrumbs from '../../components/Breadcrumbs/DefaultBreadcrumbs'
import Search from '../../components/Search'
import regionDisplayOptions from './regionDisplayOptions'
import MapOptions from '../../components/MapOptions'
const HeaderDisplay = ({ headerType, geo_id }) => {
  
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const [getgeoid, setgeoid] = useState('')
  const [getistrue, setistrue] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [getPercentage, setPercentage] = useState('')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluHeader',
    geo_id !== undefined ? geo_id : headerType
  )
  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {
        if (
          headerdata.response_data !== null &&
          headerdata.response_data !== undefined
        ) {
          // setHeaderData(headerdata.response_data)
          setgeoid(headerdata.response_data.geo_id)
          setistrue(headerdata.response_data.istrue)
          setPercentage(headerdata.response_data.percentage)
          setDatavalue(headerdata.response_data.datavalue)
        }
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])

  if (stateDataError || errorOccured) {
    return (
      <div>
        {errorSubject !== undefined
          ? errorSubject
          : 'Unable to get the data for header'}{' '}
        : {errorSubject}
      </div>
    )
  }
  return (
    <>
      <span className={styles.firstSpan}> {getgeoid}</span>
      <span className={styles.secondSpan}>{getVectorImage(getistrue)}</span>
      <span className={getistrue ? styles.stdatavalueup : styles.datavaluedown}>
        {' '}
        {getPercentage}%{' '}
      </span>
      <span className={styles.forthSpan}> ({getDatavalue})</span>
    </>
  )
}

const Header = ({ headerType, headerNumber }) => {
  const [searchCriteria, setSearchCriteria] = React.useState([])
  const [activeCbsa, setActiveCbsa] = useState()

  const { getGeoIdandGeoType } = useGeoState()
  
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/FLU?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])

  const [activeZip3, setactiveZip3] = useState()
  const { data: activeZip3Data, error: activeZip3DataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/FLU?geo_type=ZIP3'
  )

  useEffect(() => {
    if (activeZip3Data !== null && activeZip3Data !== undefined) {
      setactiveZip3(activeZip3Data)
    }
  }, [activeZip3Data])
  return (
    <>
      {/* <div style={{ display: 'flex' }}>
        <div style={{ width: '80%' }}>
          <DefaultBreadcrumbs
            geo_id={'NAT'}
            geo_type={'NAT'}
            dashboardType={'RSV'}
          />
        </div>
        <div style={{ width: '20%' }}>
          <Search
            criteria={searchCriteria}
            onChange={setSearchCriteria}
            entity={'rsv'}
            activeMetros={null}
          />
        </div>
      </div> */}

      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
        {getGeoIdandGeoType.geo_type === 'NAT' && (  <DefaultBreadcrumbs
            geo_id={'NAT'}
            geo_type={'NAT'}
            dashboardType={'FLU'}
          />)}
         {getGeoIdandGeoType.geo_type === 'STATE' && ( <DefaultBreadcrumbs
            geo_id={getGeoIdandGeoType.geoid}
            geo_type={getGeoIdandGeoType.geo_type}
            dashboardType={'FLU'}
          />)}
          {getGeoIdandGeoType.geo_type === 'MSA' && (  <DefaultBreadcrumbs
            geo_parent={headerType}
            geo_id={getGeoIdandGeoType.geoid}
            geo_type={getGeoIdandGeoType.geo_type}
            dashboardType={'FLU'}
          />)}


          <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'flu'}
            defaultLabel={'Flu Incidence'}
          />
        </div>
        <div className={styles.pageHeaderTextValue}>
          
        {getGeoIdandGeoType.geo_type === 'NAT' && <HeaderDisplay
            headerType={headerType}
            dashNo={headerNumber}
          ></HeaderDisplay>}

          {getGeoIdandGeoType.geo_type === 'STATE' && (
            <>
            <HeaderDisplay
            headerType={headerType}
            dashNo={headerNumber}
          ></HeaderDisplay>
            <HeaderDisplay
              headerType={headerType}
              dashNo={2}
              geo_id={getGeoIdandGeoType.geoid}
            ></HeaderDisplay>
            </>
          )}
          {getGeoIdandGeoType.geo_type === 'MSA' && (
            <>
              <HeaderDisplay headerType={'NAT'} geo_id={'NAT'}></HeaderDisplay>
              <HeaderDisplay
                headerType={'STATE'}
                geo_id={headerType}
              ></HeaderDisplay>
              <HeaderDisplay headerType={'MSA'} geo_id={getGeoIdandGeoType.geoid}></HeaderDisplay>
              </>
          )}
          <MapOptions
            regionDisplayOptions={regionDisplayOptions}
            activeCbsa={activeCbsa}
            activeZip3={activeZip3}
            entity={'Flu'}
          />
          {/* <div className={styles.pageHeaderSearch}>
            {activeCbsa !== undefined && (
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                entity={'Flu'}
                activeMetros={activeCbsa}
              />
            )}
          </div> */}
        </div>
      </div>
    </>
  )
}
export default Header
