import { useState, useEffect } from 'react'
import { useGeoFetch } from '../components/GeoProvider'

export const useCBSADataFetch = (startDate, endDate, diseaseType) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    startDate,
    endDate,
    `${diseaseType}Locations`,
    'activelocations?geo_type=MSA'
  )

  useEffect(() => {
    setLoading(true)
    if (activeCbsasData?.length > 0) {
      setData(activeCbsasData)
      setLoading(false)
    }

    if (activeCbsasDataError) {
      setError(true)
      setLoading(false)
    }
  }, [activeCbsasData, activeCbsasDataError])

  return { data, error, loading }
}
