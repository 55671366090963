import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../components/DisplayDateFlu/DisplayDateFluNew'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/Map/emptyMap'
import Timeline from '../../components/Timeline'
import Map from '../../components/Map'
// import MapCollection from '../../components/MapCollection'
import toolTipFormatter from './toolTipFormatter'
import MapLegend from './cdcMapLegendHorizontal'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
const DashboardMap = ({ zoomLocation }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [metros, setMetros] = useState()
  const [getStats, setStats] = useState()
  const [getMSAStats, setMSAStats] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
  } = useGeoState()
  
  var geo_parent='US'

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'rsvMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'rsvMap',
    'MSA'
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Provider Dashboard success',
          user: user,
        })
        setMetros(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setMetros(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'rsvLocations',
    'activelocations?geo_type=MSA' 
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvStats',
    'US/'
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])
  const { data: msaStats, error: msaDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvStats',
     'MSA/'
  )

  useEffect(() => {
    if (msaStats !== null && msaStats !== undefined) {
      if (msaStats.response_code === 200) {
        setMSAStats(msaStats.response_data)
      } else if (msaStats.response_code === 404) {
        setMSAStats(msaStats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaStats.response_subject}, ${msaStats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(msaStats.response_code)
        setErrorMessage(msaStats.response_message)
        setErrorSubject(msaStats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaStats])


  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])
  if (stateDataError || MapError || errorOccured||MapmsaError||msaDataError) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
              {getStats !== undefined ? (
                <SideTable stateData={getStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
          {regionSelected === 'metro' && (
            <>
              {getMSAStats !== undefined ? (
                <SideTable stateData={getMSAStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {getMapStates !== undefined && getStateJson ? (
              <>
                <div className={`${styles.providerHeader}`}>
                   <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'RSV'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
                </div>
                <div className={styles.legendDiv}>
                <MapLegend />
                </div>
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAP"
                  enableButtons={true}
                  chartRef={chartRef}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="RSV"
                />

               
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
           {regionSelected === 'metro' && (
          <>
            {metros !== undefined &&
            getMapStates !== undefined &&
            activeCbsa !== undefined &&
            getCBSAJsonData ? (
              <>
               <div className={`${styles.providerHeader}`}>
               <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'RSV'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
               </div>
               <div className={styles.legendDiv}>
                <MapLegend />
                </div>
              <Map
              toolTipFormatter={toolTipFormatter}
              activeCbsasData={activeCbsa}
              statesData={getMapStates}
              cbsaData={metros}
              mapOptions={mapOptions}
              dynamicMapTitle={true}
              mapType="RSVMAPMSA"
              enableButtons={true}
              zoomLocation={zoomLocation}
              chartRef={chartRef}
              mapDataType={1}
              // mapDataType={geo_type === 'STATE' ? 0 : 1}
              JsonData={getCBSAJsonData}
              stateJson={getStateJson}
              dashboardType="RSV"
            />
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
